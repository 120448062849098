import { FormatColorResetSharp } from '@mui/icons-material';
import { Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { IDimensions } from '../../containers/Reports/IReports';
import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/Input';
import { useTheme } from '@emotion/react';
import DeleteIcon from '../../assets/Images/deleteIconPurple';
import AddIcon from '@mui/icons-material/Add';
import './FilterModal.scss';

interface IDimensionsProps {
	selector: string;
	parameterList: any;
	operatorList: any;
	selectedConditions?: IDimensions[];
	onConditionUpdated: Function;
	dimensionError?: any;
	hideOrTab?: boolean;
	hideAndTab?: boolean;
	disableAndRelation?: boolean;
	customParameterList?: any;
	campaignTypeArr?: any;
	addButton?: boolean;
	realtionOperator?: any;
	deleteIconColor?: any;
}
interface DimensionError {
	name: string;
	index: number;
}

const Dimensions: React.FC<IDimensionsProps> = (props: any) => {
	const theme: any = useTheme();
	const [campaignTypeSelected, setCampaignTypeSelected]: any = useState(false);
	const [selectedDimensions, setSelectedDimensions] = useState<
		Array<IDimensions>
	>(
		props?.customParameterList
			? [
					{
						type: '',
						parameter: '',
						operator: '',
						value: '',
						relational_operator: '',
					},
			  ]
			: [
					{
						parameter: '',
						operator: '',
						value: '',
						relational_operator: '',
					},
			  ]
	);
	const [currentErrorItem, setCurrentErrorItem] = useState<DimensionError>({
		name: '',
		index: -1,
	});

	useEffect(() => {
		if (props?.selectedConditions?.length) {
			if (
				props.selectedConditions?.length > 0 &&
				selectedDimensions !== props.selectedConditions
			) {
				setSelectedDimensions([...props.selectedConditions]);
			}
			props?.selectedConditions?.map((data: any) => {
				data.parameter === 'campaign_type'
					? setCampaignTypeSelected(true)
					: setCampaignTypeSelected(false);
			});
		}
	}, [props?.selectedConditions]);

	useEffect(() => {
		if (props.dimensionError !== -1) {
			setCurrentErrorItem({
				name: '',
				index: props.dimensionError,
			});
		}
	}, [props.dimensionError]);

	const onDimensionUpdated = () => {
		props.onConditionUpdated(selectedDimensions);
	};
	const onAddCondition = (evt: any, dimensionIndex: any) => {
		if (!selectedDimensions[dimensionIndex].parameter) {
			setCurrentErrorItem({
				name: 'parameter',
				index: dimensionIndex,
			});
		} else if (!selectedDimensions[dimensionIndex].operator) {
			setCurrentErrorItem({
				name: 'operator',
				index: dimensionIndex,
			});
		} else if (!selectedDimensions[dimensionIndex].value?.trim()) {
			setCurrentErrorItem({
				name: 'value',
				index: dimensionIndex,
			});
		} else {
			selectedDimensions[dimensionIndex].relational_operator =
				evt.target.innerText;
			if (dimensionIndex === selectedDimensions.length - 1) {
				props?.customParameterList
					? selectedDimensions.push({
							type: '',
							parameter: '',
							value: '',
							operator: '',
							relational_operator: '',
					  })
					: selectedDimensions.push({
							parameter: '',
							value: '',
							operator: '',
							relational_operator: '',
					  });
			}
			setSelectedDimensions([...selectedDimensions]);
			console.log(
				selectedDimensions,
				'selectedDimensions in else block -----------'
			);
			setCampaignTypeSelected(false);
			onDimensionUpdated();
		}
	};
	const onDeleteConditon = (dimensionIndex: any) => {
		if (dimensionIndex === selectedDimensions.length - 1) {
			selectedDimensions[dimensionIndex - 1].relational_operator = '';
		}
		selectedDimensions.splice(dimensionIndex, 1);
		setSelectedDimensions([...selectedDimensions]);
		onDimensionUpdated();
	};
	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
		newFilter: props?.deleteIconColor ? true : false,
	};

	const checkRelationalOperator = (relational_operator: any) => {
		if (relational_operator == 1) {
			return 'AND';
		}
		if (relational_operator == 2) {
			return 'OR';
		}
	};
	return (
		<div className='filterModal__conditions'>
			{selectedDimensions.map((dimension: any, index: any) => (
				<Grid container xs={12} justifyContent='space-between' mb={2}>
					{props?.customParameterList ? (
						<Grid item xs={2}>
							<Dropdown
								valueSelector='value'
								itemList={[
									{
										name: 'Include',
										value: 1,
									},
									{
										name: 'Exclude',
										value: 0,
									},
								]}
								label='name'
								defaultValue={dimension.type}
								name='Type'
								labelName='Type'
								onChange={(evt: any) => {
									selectedDimensions[index].type = evt.target.value;
									if (currentErrorItem.index === index) {
										setSelectedDimensions([...selectedDimensions]);
									}
									onDimensionUpdated();
								}}
								error={!dimension.type && currentErrorItem.index === index}
							/>
						</Grid>
					) : (
						<></>
					)}
					<Grid
						item
						xs={props?.addButton ? 3.5 : 3}
						className='filterModal__conditions__item'
						style={{
							maxWidth: props?.customParameterList ? '20%' : '',
							paddingLeft: props?.customParameterList ? '0.5rem' : '',
						}}
					>
						<Dropdown
							itemList={props.parameterList}
							label='name'
							valueSelector='value'
							name={'parameter'}
							onChange={(evt: any) => {
								selectedDimensions[index].parameter = evt.target.value;
								if (evt.target.value === 'campaign_type') {
									setCampaignTypeSelected(true);
								} else {
									setCampaignTypeSelected(false);
									selectedDimensions[index].value = '';
								}
								if (currentErrorItem.index === index) {
									setSelectedDimensions([...selectedDimensions]);
								}
								onDimensionUpdated();
							}}
							defaultValue={dimension.parameter}
							labelName={'Parameter'}
							error={!dimension.parameter && currentErrorItem.index === index}
						/>
					</Grid>
					<Grid
						item
						xs={props?.addButton ? 3.5 : 3}
						className='filterModal__conditions__item'
						style={{ maxWidth: props?.customParameterList ? '20%' : '' }}
					>
						<Dropdown
							valueSelector='value'
							itemList={props.operatorList}
							label='name'
							name={'operator'}
							onChange={(evt: any) => {
								selectedDimensions[index].operator = evt.target.value;
								if (currentErrorItem.index === index) {
									setSelectedDimensions([...selectedDimensions]);
								}
								onDimensionUpdated();
							}}
							error={!dimension.operator && currentErrorItem.index === index}
							defaultValue={dimension.operator}
							labelName={'Operators'}
						/>
					</Grid>
					<Grid
						item
						xs={props?.addButton ? 3.5 : 3}
						className='filterModal__conditions__item'
						style={{ maxWidth: props?.customParameterList ? '18%' : '' }}
					>
						{selectedDimensions[index]?.parameter === 'campaign_type' ||
						(campaignTypeSelected &&
							selectedDimensions[index]?.parameter === 'campaign_type') ? (
							<Dropdown
								valueSelector='value'
								itemList={props.campaignTypeArr}
								label='name'
								name={'Campaign Type'}
								onChange={(evt: any) => {
									if (selectedDimensions[index].parameter === 'campaign_type') {
										setCampaignTypeSelected(true);
									}
									selectedDimensions[index].value = evt.target.value;
									onDimensionUpdated();
								}}
								error={!dimension.value && currentErrorItem.index === index}
								defaultValue={dimension.value}
								labelName={'Campaign Type'}
							/>
						) : (
							<Input
								name={'Value'}
								placeholder='Enter Value'
								label={'Value'}
								defaultValue={dimension.value}
								onChange={(evt: any) => {
									selectedDimensions[index].value = evt.target.value;
									onDimensionUpdated();
								}}
								onBlur={(evt: any) => {
									if (currentErrorItem.index === index) {
										setSelectedDimensions([...selectedDimensions]);
									}
								}}
								error={
									!dimension?.value?.trim() && currentErrorItem.index === index
								}
							/>
						)}
					</Grid>
					<Grid item xs={props?.addButton ? 1.5 : 3}>
						<div className='filterModal__conditions__relations'>
							<Tabs
								value={dimension.relational_operator}
								onChange={(evt: any) => onAddCondition(evt, index)}
								style={{ justifyContent: 'space-between', width: '100%' }}
							>
								<Tab
									className={
										dimension.relational_operator === 'OR'
											? 'filterModal__conditions__selected'
											: 'filterModal__conditions__relations__button'
									}
									value={'OR'}
									label='OR'
									style={{
										display: props.hideOrTab ? 'none' : 'visible',
									}}
								/>
								<Tab
									disabled={props.disableAndRelation ? true : false}
									className={
										dimension.relational_operator === 'AND'
											? 'filterModal__conditions__selected'
											: 'filterModal__conditions__relations__button'
									}
									value={'AND'}
									label='AND'
									style={{
										display: props.hideAndTab ? 'none' : 'visible',
									}}
								/>
								{/* ------------------------------new add button added once confirmed write css in css file not in here----------------------------------------- */}
								{props?.addButton ? (
									<span
										style={{
											border: '1px solid grey',
											borderRadius: '50%',
											padding: '5px',
											display: 'flex',
											height: '2.5rem',
											width: '2.5rem',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										<AddIcon
											color='primary'
											fontSize='large'
											onClick={(evt: any) => onAddCondition(evt, index)}
										/>
									</span>
								) : (
									<></>
								)}
								<span
									style={{ minWidth: props?.deleteIconColor ? '' : '42px' }}
								>
									{selectedDimensions.length > 1 ? (
										<span
											className={
												props?.deleteIconColor
													? 'filterModal__conditions__relations__deleteButton'
													: 'filterModal__conditions__relations__button'
											}
											style={
												selectedDimensions.length <= 1 ? { opacity: 0 } : {}
											}
										>
											<IconButton
												onClick={() => onDeleteConditon(index)}
												disableRipple={true}
											>
												{/* <img
													src={DeleteIcon}
													alt='Delete Icon'
													className='filterModal__conditions__relations__delete'
												/> */}
												<DeleteIcon {...data} />
											</IconButton>
										</span>
									) : null}
								</span>
							</Tabs>
						</div>
					</Grid>
					{selectedDimensions.length > 1 && props?.realtionOperator ? (
						<Grid item xs={12}>
							<div className='filterModal__conditions__seprator'>
								<span
									style={{
										display:
											selectedDimensions?.length - 1 == index
												? 'none'
												: 'block',
									}}
								>
									{checkRelationalOperator(props?.realtionOperator)}
								</span>
							</div>
						</Grid>
					) : (
						dimension.relational_operator && (
							<Grid item xs={12}>
								<div className='filterModal__conditions__seprator'>
									<span>{dimension.relational_operator}</span>
								</div>
							</Grid>
						)
					)}
				</Grid>
			))}
		</div>
	);
};

export default Dimensions;
